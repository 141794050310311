<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signup-on': this.state == 'signup',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #f1eefb"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img src="media/logos/logo.png" class="max-h-70px mr-4" alt="" />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #3f0fd3"
          >
            Inscription
          </h3>
        </div>
        <div class="d-none d-lg-flex flex-column-auto flex-column" style="margin: auto auto 80px auto;">
          <img src="media/bg/auth-bg.png" style="width: 500px; max-width: 100%;">
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <p class="text-muted font-weight-bold font-size-h6">
              Étape {{ step }}/{{ totalSteps }}
            </p>
            <!--begin::Step1 Form-->
            <form
              v-show="step === 1"
              class="form"
              novalidate="novalidate"
              id="step1_form"
            >
              <div class="pb-8 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Entreprise
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Renseignez les informations de votre entreprise.
                </p>
              </div>

              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  v-model="newUser.company.siret"
                  type="text"
                  placeholder="SIRET"
                  name="siret"
                  ref="siret"
                  autocomplete="off"
                  @input="onSiretInput"
                />
              </div>
              <div v-show="newUser.company.siret.replace(/\D/g, '').length === 14">
                <div class="form-group">
                  <b-row>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.name"
                        type="text"
                        placeholder="Raison sociale"
                        name="name"
                        ref="name"
                        autocomplete="off"
                      />
                    </b-col>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.legalStatus"
                        type="text"
                        placeholder="Statut juridique (e.g. SAS)"
                        name="legalStatus"
                        ref="legalStatus"
                        autocomplete="off"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.vatNumber"
                        type="text"
                        placeholder="N° TVA"
                        name="vatNumber"
                        ref="vatNumber"
                        autocomplete="off"
                      />
                    </b-col>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.activityCode"
                        type="text"
                        placeholder="Code NAF / APE"
                        name="activityCode"
                        ref="activityCode"
                        autocomplete="off"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.capital"
                        type="number"
                        min="0"
                        step="1"
                        placeholder="Capital social (en €)"
                        name="capital"
                        ref="capital"
                        autocomplete="off"
                      />
                    </b-col>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.registrationCity"
                        type="text"
                        placeholder="Ville d'immatriculation (RCS)"
                        name="registrationCity"
                        ref="registrationCity"
                        autocomplete="off"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                      font-size-h6
                    "
                    v-model="newUser.company.address.line1"
                    placeholder="Adresse ligne 1"
                    name="addressLine1"
                    ref="addressLine1"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                      font-size-h6
                    "
                    v-model="newUser.company.address.line2"
                    placeholder="Adresse ligne 2"
                    name="addressLine2"
                    ref="addressLine2"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.address.postalCode"
                        placeholder="Code postal"
                        name="postalCode"
                        ref="postalCode"
                        autocomplete="off"
                      />
                    </b-col>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.address.city"
                        placeholder="Ville"
                        name="city"
                        ref="city"
                        autocomplete="off"
                      />
                    </b-col>
                    <b-col>
                      <input
                        class="
                          form-control form-control-solid
                          h-auto
                          py-7
                          px-6
                          rounded-lg
                          font-size-h6
                        "
                        v-model="newUser.company.address.country"
                        placeholder="Pays"
                        name="country"
                        ref="country"
                        autocomplete="off"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </form>
            <!--end::Step1 Form-->
            <!--begin::Step2 Form-->
            <form
              v-show="step === 2"
              class="form"
              novalidate="novalidate"
              id="step2_form"
            >
              <div class="pb-8 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Représentant légal
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Le compte administrateur sera créé à partir des informations
                  du représentant légal.
                </p>
              </div>
              <div class="form-group">
                <b-form-radio-group
                  v-model="newUser.company.legalRepresentative.civility"
                  :options="[
                    { text: 'M', value: 'M' },
                    { text: 'Mme', value: 'F' },
                  ]"
                  name="legalRepCivility"
                />
              </div>
              <div class="d-flex flex-row">
                <div class="form-group mr-1">
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                      font-size-h6
                    "
                    v-model="newUser.company.legalRepresentative.firstname"
                    type="text"
                    placeholder="Prénom"
                    name="legalRepFirstname"
                    ref="legalRepFirstname"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group ml-1">
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                      font-size-h6
                    "
                    v-model="newUser.company.legalRepresentative.lastname"
                    type="text"
                    placeholder="Nom"
                    name="legalRepLastname"
                    ref="legalRepLastname"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  v-model="newUser.company.legalRepresentative.quality"
                  type="text"
                  placeholder="Qualité (e.g. Président, Gérant)"
                  name="legalRepQuality"
                  ref="legalRepQuality"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  v-model="newUser.company.legalRepresentative.email"
                  type="email"
                  placeholder="Email"
                  name="legalRepEmail"
                  ref="legalRepEmail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  v-model="newUser.password"
                  type="password"
                  placeholder="Mot de passe"
                  name="legalRepPassword"
                  ref="legalRepPassword"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <b-spinner v-if="cgsLoading" style="float: right;" variant="primary" />
                <label class="checkbox mb-0">
                  <input
                    type="checkbox"
                    name="agreeConditionsOfService"
                    v-model="newUser.agreeConditionsOfService"
                  />
                  <span class="mr-2"></span>
                  <div class="d-block d-flex-none">
                    J’ai lu et accepté les 
                    <a href="#" @click.prevent="previewCgs()">
                      Conditions Générales de Service
                    </a>
                  </div>
                </label>
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input
                    type="checkbox"
                    name="agreePrivacyPolicy"
                    v-model="newUser.agreePrivacyPolicy"
                  />
                  <span class="mr-2"></span>
                  <div class="d-block d-flex-none">
                    Je consens au traitement de mes données personnelles conformément à la 
                    <a href="/politique-de-confidentialite.pdf" target="_blank">
                      Politique de Confidentialité
                    </a>.
                  </div>
                </label>
              </div>

              <div class="form-group">
                <p class="text-muted font-weight-bold font-size-h4">
                  Suite à votre inscription, les Conditions Générales de Service
                  définitives vous seront envoyées par email.<br />
                  Un lien de confirmation vous permettra de les signer
                  électroniquement.
                </p>
              </div>
            </form>
            <!--end::Step2 Form-->

            <!--begin::Buttons section-->
            <div class="form-group d-flex flex-wrap pb-lg-0 pb-3 mt-8">
              <button
                v-if="step < totalSteps"
                ref="step_submit"
                class="
                  btn btn-primary
                  font-weight-bolder font-size-h6
                  px-8
                  py-4
                  my-3
                  mr-2
                "
                @click="toNextStep"
              >
                Étape suivante
              </button>
              <button
                v-else
                ref="signup_submit"
                class="
                  btn btn-primary
                  font-weight-bolder font-size-h6
                  px-8
                  py-4
                  my-3
                  mr-2
                "
                @click="createUser"
              >
                Créer votre compte
              </button>
              <router-link
                v-if="step === 1"
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bolder font-size-h6
                  px-8
                  py-4
                  my-3
                "
                to="/login"
              >
                Se connecter
              </router-link>
              <button
                v-else
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bolder font-size-h6
                  px-8
                  py-4
                  my-3
                "
                @click="toPreviousStep"
              >
                Précédent
              </button>
            </div>
            <!--end::Buttons section-->
          </div>
          <!--end::Signup-->
        </div>
        <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import CategoriesJuridiques from "@/helpers/CategoriesJuridiques";
import iso from "iso-3166-1";
// import Swal from "sweetalert2";

import axios from "axios";

export default {
  name: "register",
  data() {
    return {
      state: "signup",
      fv: [],
      step: 1,
      totalSteps: 2,
      cgsLoading: false,
      newUser: {
        company: {
          name: "",
          siret: "",
          address: {
            line1: "",
            line2: "",
            postalCode: "",
            city: "",
            state: "",
            country: "",
          },
          vatNumber: "",
          activityCode: "",
          legalRepresentative: {
            civility: "",
            firstname: "",
            lastname: "",
            quality: "",
            email: "",
          },
        },
        password: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const step1_form = KTUtil.getById("step1_form");
    const step2_form = KTUtil.getById("step2_form");

    this.fv[0] = formValidation(step1_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "La raison sociale est requise",
            },
          },
        },
        legalStatus: {
          validators: {
            notEmpty: {
              message: "Le statut juridique (e.g. SAS) est requis",
            },
          },
        },
        capital: {
          validators: {
            notEmpty: {
              message: "Le capital social (€) est requis",
            },
          },
        },
        registrationCity: {
          validators: {
            notEmpty: {
              message: "Le ville d'immatriculation (RCS) est requise",
            },
          },
        },
        vatNumber: {
          validators: {
            notEmpty: {
              message: "Le N° de TVA intracommunautaire est requis",
            },
          },
        },
        siret: {
          validators: {
            notEmpty: {
              message: "Le SIRET est requis",
            },
            stringLength: {
              min: 14,
              // max: 14,
              message: "Veuillez renseigner un SIRET valide",
            },
            regexp: {
              regexp: /^[\s0-9]*$/,
              message: "Un SIRET doit contenir 14 chiffres",
            },
          },
        },
        addressLine1: {
          validators: {
            notEmpty: {
              message: "L'adresse est requise",
            },
          },
        },
        postalCode: {
          validators: {
            notEmpty: {
              message: "Le code postal est requis",
            },
          },
        },
        city: {
          validators: {
            notEmpty: {
              message: "La ville est requise",
            },
          },
        },
        activityCode: {
          validators: {
            notEmpty: {
              message: "Le code NAF est requis",
            },
          },
        },
        country: {
          validators: {
            notEmpty: {
              message: "Le pays est requis",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv[1] = formValidation(step2_form, {
      fields: {
        legalRepCivility: {
          validators: {
            notEmpty: {
              message: "La civilité est requise",
            },
          },
        },
        legalRepLastname: {
          validators: {
            notEmpty: {
              message: "Le nom est requis",
            },
          },
        },
        legalRepFirstname: {
          validators: {
            notEmpty: {
              message: "Le prénom est requis",
            },
          },
        },
        legalRepQuality: {
          validators: {
            notEmpty: {
              message: "La qualité est requise",
            },
          },
        },
        legalRepEmail: {
          validators: {
            notEmpty: {
              message: "L'email est requis",
            },
          },
        },
        legalRepPassword: {
          validators: {
            notEmpty: {
              message: "Le mot de passe est requis",
            },
            stringLength: {
              min: 6,
              message: "Votre mot de passe doit contenir au moins 6 caractères",
            },
          },
        },
        agreePrivacyPolicy: {
          validators: {
            notEmpty: {
              message: "Veuillez accepter la Politique de Confidentialité",
            },
          },
        },
        agreeConditionsOfService: {
          validators: {
            notEmpty: {
              message: "Veuillez accepter le projet de Conditions Générales de Service",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    createUser() {
      // set spinner to submit button
      const submitButton = this.$refs["signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.fv[this.step - 1].validate().then((res) => {
        if (res === "Valid") {
          const isoCountry = iso.whereCountry(
            this.newUser.company.address.country
          );
          if (isoCountry) {
            this.newUser.company.address.country = isoCountry.alpha2;
          }

          this.newUser.company.siret = this.newUser.company.siret.replace(/\D/g,'');

          // send register request
          this.$store
            .dispatch(REGISTER, this.newUser)
            .then(() => {
              this.$router.push("/treatments");
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            })
            .catch((err) => {
              console.warn(err);
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            });
        }
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      });
    },
    onSiretInput(e) {
      const siret = e.target.value.replace(/\D/g,'');
      if (siret.length !== 14) return;
      axios
        .get(
          `https://entreprise.data.gouv.fr/api/sirene/v3/etablissements/${siret}`
        )
        .then(({ data }) => {
          this.newUser.company.name =
            data.etablissement.unite_legale.denomination;
          this.newUser.company.legalStatus =
            CategoriesJuridiques[
              data.etablissement.unite_legale.categorie_juridique
            ];
          this.newUser.company.capital = "";
          this.newUser.company.registrationCity = "";
          this.newUser.company.activityCode =
            data.etablissement.activite_principale;
          this.newUser.company.vatNumber =
            data.etablissement.unite_legale.numero_tva_intra;
          this.newUser.company.address.line1 = `${data.etablissement.numero_voie} ${data.etablissement.type_voie} ${data.etablissement.libelle_voie}`;
          this.newUser.company.address.line2 = "";
          this.newUser.company.address.postalCode =
            data.etablissement.code_postal;
          this.newUser.company.address.city =
            data.etablissement.libelle_commune;
          this.newUser.company.address.state = "";
          this.newUser.company.address.country = "FRANCE";
        })
        .catch(() => {});
    },
    previewCgs() {
      this.cgsLoading = true;

      ApiService
        .post('/auth/preview-terms', this.newUser.company, { responseType: 'blob' })
        .then(({ data }) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileUrl = window.URL.createObjectURL(file);
          window.open(fileUrl);
          
          this.cgsLoading = false;
        })
        .catch(() => {
          this.cgsLoading = false;
        });
    },
    /**
     * @description validates the current step's form and goes to the next step
     */
    toNextStep() {
      //field validation
      this.fv[this.step - 1].validate().then((res) => {
        if (res === "Valid") {
          //goes to next step
          if (this.step < this.totalSteps) this.step++;
        }
      });
    },
    /**
     * @description goes back to the previous step
     */
    toPreviousStep() {
      if (this.step > 1) this.step--;
    },
  },
};
</script>

<style lang="scss">
.login.login-1 .login-content .login-form {
  max-width: 565px;
}
.custom-control-input.is-valid:checked ~ .custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: inherit;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3f0fd3;
  background-color: #3f0fd3;
  box-shadow: none;
}
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #3f0fd3;
}
</style>